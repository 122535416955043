
export class ServerConfig  {

    server_admin_api: string;
    server_auth_api: string;
    constructor() {

        //this.server_admin_api = "http://localhost:8080/api/v1/";
        //this.server_auth_api = "http://localhost:8080/api/auth/";
        this.server_admin_api = "http://10.1.3.200:8080/api/v1/";
        this.server_auth_api = "http://10.1.3.200:8080/api/auth/";

        // this.server_admin_api = "/api/v1/";
        // this.server_auth_api = "/api/auth/";
    }
  }
