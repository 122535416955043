import {Injectable} from '@angular/core';

export interface NavigationItem {
  id: string;
  title: string;
  // type: 'item' | 'collapse' | 'group';
  type: 'item' | 'collapse' | 'group';
  translate?: string;
  icon?: string;
  hidden?: boolean;
  url?: string;
  classes?: string;
  exactMatch?: boolean;
  external?: boolean;
  target?: boolean;
  breadcrumbs?: boolean;
  function?: any;
  badge?: {
    title?: string;
    type?: string;
  };
  children?: Navigation[];
}

export interface Navigation extends NavigationItem {
  children?: NavigationItem[];
}


const NavigationItems = [
  {
    id: 'menu',
    title: 'menu',
    type: 'group',
    icon: 'feather icon-align-left',
    children: [
    
      {
        id: 'menu-level',
        title: '회원관리(도매)',
        type: 'item',
        icon: 'feather icon-navigation',
        url: '/#/wholesale',
        external: true,
        breadcrumbs : false
      },
      {
        id: 'menu-level',
        title: '회원관리(소매)',
        type: 'item',
        icon: 'feather icon-monitor',
        url: '/#/sleeve',
        external: true,
        breadcrumbs : false
      },
      {
        id: 'menu-level',
        title: '상품관리',
        type: 'item',
        icon: 'feather icon-star-on',
        url: '/#/productadmin',
        external: true,
        breadcrumbs : false
      },
      {
        id: 'menu-level',
        title: '주문관리',
        type: 'item',
        icon: 'feather icon-star-on',
        url: '/#/Order',
        external: true,
        breadcrumbs : false
      },
      {
        id: 'menu-level',
        title: 'ND 주문관리',
        type: 'item',
        icon: 'feather icon-star-on',
        url: '/#/ndorder',
        external: true,
        breadcrumbs : false
      },
      {
        id: 'menu-level',
        title: '상품대량등록',
        type: 'item',
        icon: 'feather icon-menu',
        url: '/#/productmass',
        external: true,
        breadcrumbs : false
      },
      {
        id: 'menu-level',
        title: '도매/소매사업자 알림관리',
        type: 'item',
        icon: 'feather icon-inbox',
        url: '/#/alarm',
        external: true,
        breadcrumbs : false
      },
      {
        id: 'menu-level',
        title: '도매사업자 배너관리',
        type: 'item',
        icon: 'feather icon-inbox',
        url: '/#/banner',
        external: true,
        breadcrumbs : false
      },
      {
        id: 'menu-level',
        title: '소매사업자 배너관리',
        type: 'item',
        icon: 'feather icon-star-on',
        url: '/#/bannerslave',
        external: true,
        breadcrumbs : false
      },
      {
        id: 'menu-level',
        title: '소매사업자 화면관리',
        type: 'item',
        icon: 'feather icon-star-on',
        url: '/#/sleevebanner',
        external: true,
        breadcrumbs : false
      },
      {
        id: 'menu-level',
        title: '앱 버전 관리',
        type: 'item',
        icon: 'feather icon-inbox',
        url: '/#/productsubadmin',
        external: true,
        breadcrumbs : false
      }
   

    ]
  }
];

@Injectable()
export class NavigationItem {
  public get() {
    return NavigationItems;
  }
}
