import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Admin } from '../model/admin.model';
import { TokenStorageService } from './token-storage.service';

import { ServerConfig } from './serverconfig';




@Injectable({
  providedIn: 'root'
})
export class AuthService {
  AUTH_API: string;

  id  : string;

  alliance  = "";
  alliancerate = 0;
  allianceName ="";
  

  constructor(
    private http: HttpClient,
    private tokenStorageService: TokenStorageService,
    public serverConfig: ServerConfig
    ) {
      this.AUTH_API = this.serverConfig.server_auth_api;
    }

  authenticate(admin: Admin) {
    return this.http.post<any>(this.AUTH_API + 'signin', admin).pipe(
     map(
       userData => {
        sessionStorage.setItem('username', userData.id);
        console.log(userData.id);

        const tokenStr = 'scopeEdrSecretKey' + userData.token;
        sessionStorage.setItem('token', tokenStr);
        return userData;
       }
    )
   );
  }

  logOut() {
    sessionStorage.removeItem('username');
    sessionStorage.removeItem('token');

    this.tokenStorageService.signOut();
  }
}
