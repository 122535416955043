
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FileExceptModel, NetworkExceptModel, StorageModel, YaraModel, NetworkBlockExceptModel } from '../model/policy.model';
import 'rxjs/add/operator/map';
import { ServerConfig } from './serverconfig';

@Injectable({
  providedIn: 'root'
})
export class PolicyService {
  API_URL: string;

  constructor(private http: HttpClient, public serverConfig: ServerConfig) {
    this.API_URL = this.serverConfig.server_admin_api;
   }


  /******  파일 예외 정책 API *************/
  getFileExceptPolicyList(): Observable<any> {
    return this.http.get(this.API_URL + 'fileexcept');
  }

  createFileExceptPolicy(fileExceptPolicy: object): Observable<any> {
    return this.http.post(this.API_URL + 'fileexcept', fileExceptPolicy);
  }



  /******  네트워크 예외 정책 API *************/

  getNetworkExceptPolicyList(): Observable<any> {
    return this.http.get(this.API_URL + 'networkexcept');
  }

  createNetworkExceptPolicy(NetworkExceptPolicy: object): Observable<any> {
    return this.http.post(this.API_URL + 'networkexcept', NetworkExceptPolicy);
  }


  /******  스토리지 정책 API *************/

  getStoragePolicyList(): Observable<any> {
    return this.http.get(this.API_URL + 'storage');
  }

  updateStoragePolicy(StoragePolicy: object): Observable<any> {
    return this.http.put(this.API_URL + 'storage', StoragePolicy);
  }

  deleteStoragePolicy(id: number): Observable<any> {
    return this.http.delete(this.API_URL + 'storage' + `/${id}`, { responseType: 'text' });
  }



  /******  Yara 정책 API *************/

  getYaraPolicyList(): Observable<any> {
    return this.http.get(this.API_URL + 'yara');
  }

  getYaraChart(): Observable<any> {
    return this.http.get(this.API_URL + 'yarachart');
  }

  getYara(name): Observable<any> {

    console.log("YARA POLICY", this.API_URL + 'yara/' + name);
    return this.http.get(this.API_URL + 'yara/' + name);
  }

  createYaraPolicy(YaraPolicy: object): Observable<any> {
    return this.http.post(this.API_URL + 'yara', YaraPolicy);
  }

  createYaraPolicyFile(YaraPolicys: object): Observable<any> {
    return this.http.post(this.API_URL + 'yarafile', YaraPolicys);
  }

  /******  네트워크 차단 예외 정책 API *************/
  getNetworkBlockExceptPolicyList(): Observable<any> {
    return this.http.get(this.API_URL + 'networkblockexcept');
  }

  createNetworkBlockExceptPolicy(NetworkBlockExceptPolicy: object): Observable<any> {
    return this.http.post(this.API_URL + 'networkblockexcept', NetworkBlockExceptPolicy);
  }
}
