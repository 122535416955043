import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse} from '@angular/common/http';

import {ServerConfig} from './serverconfig';
import { retry, catchError } from 'rxjs/operators';

import { FileMapStatus } from '../model/filemap.model';



@Injectable({
  providedIn: 'root'
})
export class NodeService {
  API_URL: string;



  constructor(private http: HttpClient, public serverConfig: ServerConfig) {
    this.API_URL = this.serverConfig.server_admin_api;
  }


  getNodeList(): Observable <any> {
    return this.http.get(this.API_URL + 'node');
  }

  getNodeBlockList(): Observable<any> {
    return this.http.get(this.API_URL + 'blocknode');
  }

  getNodeDetectList(): Observable<any> {
    return this.http.get(this.API_URL + 'detectnode')
    .pipe(
      retry(2),
      catchError(this.handleError)
    );
  }

  changeAdminStatus(filemap: FileMapStatus): Observable<any> {
    return this.http.post(this.API_URL + 'filemap/admin/status', filemap);
  }


  getFileMapList(): Observable<any> {
    return this.http.get(this.API_URL + 'filemap');
  }

  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  }

}
