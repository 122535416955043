
  import { Injectable } from '@angular/core';
  import { HttpClient } from '@angular/common/http';
  import { Observable } from 'rxjs';
  import { ServerConfig } from './serverconfig';

  @Injectable({
    providedIn: 'root'
  })
  export class AdminService {
    API_URL: string;

    constructor(private http: HttpClient, public serverConfig: ServerConfig) {
      this.API_URL = this.serverConfig.server_admin_api;
     }

    getPublicContent(): Observable<any> {
      return this.http.get(this.API_URL + 'all', { responseType: 'text' });
    }

    getUserBoard(): Observable<any> {
      return this.http.get(this.API_URL + 'user', { responseType: 'text' });
    }

    getModeratorBoard(): Observable<any> {
      return this.http.get(this.API_URL + 'mod', { responseType: 'text' });
    }

    getAdminBoard(): Observable<any> {
      return this.http.get(this.API_URL + 'admin', { responseType: 'text' });
    }
  }
